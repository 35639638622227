@import '../../variable';

.data-history {
  font-size: 13px;
  // margin-left: 15px;
  // display: grid;
  // grid-template-rows: 2 / 3;

  display: grid;
  grid-column: 1 / 2;
  grid-template-columns: repeat(
    auto-fit,
    minmax(50%, 1fr)
  ); // 리사이징시 안짤리고 꽉차게

  * {
    color: #fff;
  }
  @include md {
    display: block;
    grid-column: inherit;
  }

  .recharts-legend-wrapper {
    margin-top: -10px;
    margin-right: -10px;

    .recharts-legend-item-text {
      font-size: 12px;
    }
  }

  h3 {
    margin-bottom: 15px;
  }

  .chartbox {
    width: calc(100% + 7px);
  }

  > article {
    @include cardStyle;
    // padding-right: 7px;
    background: $themColorDark;
  }
  .history-list {
    list-style: none;
    margin: 10px 0 5px;
    display: flex;
    // padding-right: 10px;
    // flex-wrap: wrap;

    * {
      color: #fff;
    }
    li {
      flex: 1;
      background-color: rgba(255, 255, 255, 0.05);
      padding: 15px 8px;
      border-radius: 3px;
      text-align: right;

      + li {
        margin-left: 3px;
      }

      @include lg {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          margin-left: 0;
        }
      }
      @include md {
        padding: 8px 8px;

        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          margin-left: 0;
        }
      }

      .date {
        display: block;
        text-align: left;
        margin-bottom: 8px;
        margin-right: 5px;
        min-width: 60px;
        opacity: 0.5;
        font-weight: 500;
      }
      strong {
        // min-width: 90px;
        font-weight: 500;
        display: block;
        text-align: right;
        // margin-right: 5px;
        font-size: 13px;
        margin-bottom: 5px;

        @include lg {
          font-size: 12px;
        }
      }
      .plus {
        display: inline-flex;
        // min-width: 50px;
        font-size: 13px;
        color: #f84040;
        margin-left: 5px;
        padding: 2px 4px;
        border-radius: 3px;
        background-color: rgba(#f84040, 0.1);

        @include md {
          font-size: 13px;
        }
      }
    }
  }
}
